<div class="p-grid p-nogutter">
  <div class="p-col-4">
    <div class="home-button-container">
      <p-card routerLink="/purchasing" header="Buy Inventory">
        <div>Search for products that you need to restock and see what stores near you have that item as slow moving in their inventory.</div>
      </p-card>
    </div>
  </div>
  <!-- <div class="p-col-4">
    <div class="home-button-container">
      <p-card routerLink="/selling" header="Sell Inventory">
        <div>Search for products of which you have an excess and see which stores near you are running low on that item.</div>
      </p-card>
    </div>
  </div> -->
  <div class="p-col-4">
    <div class="home-button-container">
      <p-card routerLink="/shoppinglist" header="Shopping List">
        <div>View Shopping lists created by stores you manage.</div>
      </p-card>
    </div>
  </div>
  <div class="p-col-4">
    <div class="home-button-container">
      <p-card routerLink="/slow-moving" header="Slow Moving">
        <div>View and manage Slow Moving Requests for products to and from your stores.</div>
        <br>
        <!-- <div>{{slowMovingRequestStats.storeNumber}}</div>
        <div>{{slowMovingRequestStats.companyNumber}}</div> -->
        <div>Pending Requests BY Me: {{slowMovingRequestStats.byMePending}}</div>
        <div>Pending Requests FROM Me: {{slowMovingRequestStats.fromMePending}}</div>
        <div>Accepted Requests BY Me: {{slowMovingRequestStats.byMeAccepted}}</div>
        <div>Accepted Requests FROM Me: {{slowMovingRequestStats.fromMeAccepted}}</div>
      </p-card>
    </div>
  </div>
</div>
