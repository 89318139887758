import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShoppingList } from './shopping-list.interface';
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShoppingListService {
  private listToPrintSubject: BehaviorSubject<ShoppingList> = new BehaviorSubject<ShoppingList>(null);
  listToPrint$: Observable<ShoppingList> = this.listToPrintSubject.asObservable();

  private apiURL = `${environment.apiURL}/shoppinglist`;

  constructor(private http: HttpClient) { }

  get listToPrint(): ShoppingList {
    return this.listToPrintSubject.value;
  }

  setListToPrint(list: ShoppingList): void {
    this.listToPrintSubject.next(list)
  }

  async getShoppingLists(storeNumber: number) {
    return this.http.get<any>(`${this.apiURL}/store/${storeNumber}`)
      .toPromise()
      // .then(res => <ShoppingList[]>res)
      .then((data) => { return data; });
  }

  async createShoppingList(shopListJSON: any) {
    return this.http.post<any>(`${this.apiURL}`, shopListJSON)
      .toPromise()
      // .then(res => <ShoppingList[]>res)
      .then((data) => { return data; });
  }

  async getSingleShoppingList(listID: string) {
    return this.http.get<any>(`${this.apiURL}/${listID}`)
      .toPromise()
      .then(res => <ShoppingList>res)
      .then((data) => { return data; });
  }

  async deleteShoppingList(listID: string) {
    return this.http.delete<any>(`${this.apiURL}/${listID}`)
      .toPromise()
      // .then(res => <ShoppingList[]>res)
      .then((data) => { return data; });
  }

}