import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { LayoutService } from '../layout/layout.service';
import { Dialog } from '../layout/layout.interface';
import { KeycloakUser } from './auth.interface';
import { UsersService } from './users.service';
import { PeopleService } from '../people/people.service';
import { Person } from '../people/person.interface';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class AuthService {
  keycloakTokenInvalid: boolean = false;
  watchToken: boolean = false;

  constructor(
    private keycloakService: KeycloakService,
    private layoutService: LayoutService,
    private peopleService: PeopleService,
    private userService: UsersService,
  ) {
    this.watchTokenValidity();
  }

  async getUser(): Promise<Person> {
    try {
      
      const keycloakProfile = await this.keycloakService.loadUserProfile() as KeycloakUser;
      const employeeId = Number(keycloakProfile.attributes.employee_id);
      const roles = _.map(keycloakProfile.attributes.roles, (r: string) => { return r.toUpperCase()});
      const personBasic: Person = {
        displayName: `${keycloakProfile.firstName} ${keycloakProfile.lastName}`,
        employeeId: employeeId,
        email: `${keycloakProfile.email || 'UNKNOWN'}`,
        isExecutive: roles.includes('EXECUTIVE'),
        isManager: (keycloakProfile.attributes.store_numbers?.length > 1 || false),
        isTrainer: roles.includes('TRAINER'),
        isInventoryCzar: roles.includes('INVENTORY_CZAR'),
      }
      const person = await this.userService.setUserStores(personBasic, keycloakProfile)

      return await this.userService.setLoggedInUser(person);

    } catch (err) {
      console.error(err)
    }
  }

  private watchTokenValidity(): void {
    this.watchToken = true;
    const twoMinutes = 120000;
    interval(twoMinutes)
      .pipe(takeWhile(() => !this.keycloakTokenInvalid && this.watchToken))
      .subscribe(() => {
        this.checkTokenStatus();
      });
  }

  async checkTokenStatus(): Promise<void> {
    try {
      const keycloak = this.keycloakService.getKeycloakInstance();

      // updateToken checks to see if the keycloak session is valid in keycloak;
      await keycloak.updateToken(5000);

    } catch (error) {
      console.error(error);
      console.log('********************');
      console.log('ERROR UPDATING TOKEN');
      console.log('********************');
      this.showLoggedOutMessage();
      this.keycloakTokenInvalid = true;
    }
  }

  showLoggedOutMessage(): void {
    const dialog: Dialog = {
      visible: true,
      header: 'Logged Out',
      buttonText: 'Go To Login',
      buttonIcon: 'fas fa-user-lock',
      body:
        'Your session has expired or you have been logged out from another location. You will be redirected to log in.',
      func: () => {
        this.keycloakService.login();
      },
    };
    this.layoutService.updateDialog(dialog);
  }

  showMissingStoresMessage(): void {

    const dialog: Dialog = {
      visible: true,
      header: 'Missing Information',
      buttonText: 'Reload',
      buttonIcon: 'fas fa-sync-alt',
      body: "Your account doesn't have any stores assigned to it. Please contact support.",
      func: () => {
        location.reload();
      },
    };
    this.layoutService.updateDialog(dialog);
  }

  showMissingEmployeeNumberMessage(): void {

    const dialog: Dialog = {
      visible: true,
      header: 'Missing Information',
      buttonText: 'Reload',
      buttonIcon: 'fas fa-sync-alt',
      body: "Your account doesn't have an employee number assigned to it. Please contact support.",
      func: () => {
        location.reload();
      },
    };
    this.layoutService.updateDialog(dialog);
  }
}
