<p-dropdown
  *ngIf="loggedInUser?.stores?.length > 1 || loggedInUser?.isInventoryCzar || loggedInUser?.isExecutive || loggedInUser?.isTrainer"
  [options]="loggedInUser?.stores"
  (onChange)="setSelectedStore($event)"
  placeholder="Select a Store"
  optionLabel="name"
  [(ngModel)]="selectedStore"
  [style]="{'width': '260px'}"
  class="store-dropdown">
  <ng-template let-item pTemplate="selectedItem">
    <span style="vertical-align:middle; margin-left: .5em">{{item.name}}</span>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
        <div style="font-size:14px;">{{item.name}}</div>
    </div>
  </ng-template>
</p-dropdown>
