import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StoreService } from '../stores/stores.service';
import { Person } from '../people/person.interface';
import { map } from 'rxjs/operators';
import { PagedResponse } from '../shared/crud-base.service';
import { Store } from '../stores/stores.interface';
import { KeycloakUser } from './auth.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UsersService {
  private loggedInUserSubject: BehaviorSubject<Person> = new BehaviorSubject<Person>(null);
  loggedInUser$: Observable<Person> = this.loggedInUserSubject.asObservable();

  constructor (
    private storeService: StoreService
  ) { }

  get loggedInUser(): Person {
    return this.loggedInUserSubject.value;
  }

  async setLoggedInUser(person: Person): Promise<Person> {
    this.updateLoggedInUserSelectedStore();
    this.loggedInUserSubject.next(person);
    return person;
  }

  async setUserStores(person: Person, profile: KeycloakUser): Promise<Person> {
    try {
      const canSeeAllStores = person.isExecutive || person.isInventoryCzar || person.isTrainer;
      const userStoreNumbers = profile.attributes.store_numbers;
      const storeQuery = canSeeAllStores ?
        { pageSize: 'all', storeType__in: ['RETAIL', 'WHOLESALE'], isActive: true, /*select: ['storeNumber', 'name', 'location'],*/ companyNumber__in: environment.thisCompanyNumber } :
        { pageSize: 'all', storeNumber__in: userStoreNumbers, isActive: true, /*select: ['storeNumber', 'name', 'location'],*/ companyNumber__in: environment.thisCompanyNumber };
      person.stores = await this.storeService.findMany(storeQuery)
        .pipe(map((res: PagedResponse<Store>) => res.documents)).toPromise();
      person.stores.sort((a: Store, b: Store) => {
        return a.storeNumber - b.storeNumber;
      });
      person.selectedStore = canSeeAllStores ?
        person.stores[0] :
        person.stores.find((s: Store) => s.storeNumber === Number(userStoreNumbers[0]));
      this.setSelectedStore(person.selectedStore);
      
    } catch (err) {
      console.error(err);
    } finally {
      return person;
    }
  }

  updateLoggedInUserSelectedStore(): void {
    this.storeService.selectedStore.subscribe((store: Store) => {
      const user = this.loggedInUser;
      if (store && user) {
        user.selectedStore = store;
        //
        this.loggedInUserSubject.next(user);
      }
    });
  }


  isManager(person: Person): boolean {
    return person.managedStoreNumbers && person.managedStoreNumbers.length > 0;
  }


  setSelectedStore(store: Store): void {
    this.storeService.setSelectedStore(store);
  }

}
