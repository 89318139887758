import { Component, OnInit } from '@angular/core';
import { SlowMovingSocketMessage } from 'src/app/slow-moving-request/slow-moving-request.interface';
import { SlowMovingRequestService } from 'src/app/slow-moving-request/slow-moving-request.service';
import { Store } from 'src/app/stores/stores.interface';
import { StoreService } from 'src/app/stores/stores.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  store: Store;
  slowMovingRequestStats: SlowMovingSocketMessage = {
    storeNumber: 0,
    companyNumber: 'UNKNOWN'
  };
  constructor(
    private storeService: StoreService,
    private slowMovingService: SlowMovingRequestService,
  ) {}

  async ngOnInit() {
    this.storeService.selectedStore.subscribe((selectedStore: Store) => {
      this.store = selectedStore;
    });
    this.slowMovingService.listen().subscribe((requestCounts: SlowMovingSocketMessage) => {
      this.slowMovingRequestStats = requestCounts;
    })
    this.slowMovingService.triggerRequestCountSocket({storeNumber: this.store.storeNumber, companyNumber: this.store.companyNumber});
  }

}
