<div class="p-grid topbar">
  <div class="p-col-2">
    <p-menubar [model]="menuItems" styleClass="menucus">
      <ng-template pTemplate="start">
        <a href="{{portalURL}}"><img src="{{logo}}" class="p-menubar-logo"/></a>
      </ng-template>
    </p-menubar>
  </div>
  <div class="p-col">
    <!--hold my spot-->
  </div>
  <div class="p-col-2 topbar-dropdown">
    <app-store-dropdown></app-store-dropdown>
  </div>

  <div class="p-col-2 user-menu">
    <p-menu #menu [popup]="true" [model]="userMenu.menuItems"></p-menu>
    <p-button class="user-menu-button" (click)="menu.toggle($event)" label="{{userMenu.displayName}}" icon="far fa-angle-down" iconPos="right"><i class="fas fa-user-circle fa-2x"></i></p-button>
  </div>
</div>