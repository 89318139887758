<p-dialog [header]="dialog.header" modal="true" [(visible)]="dialog.visible">
  {{dialog.body}}
  <ng-template pTemplate="footer">
      <p-button class="dialog-button" type="button" [icon]="dialog.buttonIcon || 'far fa-check'"
        (click)="dialog.func()" [label]="dialog.buttonText || 'Go'"></p-button>
    </ng-template>
</p-dialog>
<div class="loading" *ngIf="loadingApp">
  <img src="assets/img/STMLoadingIcon_fullscreen.svg"/>
</div>
<router-outlet></router-outlet>