
<h3>{{selectedShoppingList.listName}}</h3>
<h2>Vendor: {{selectedShoppingList?.vendorName || ''}}</h2>

<p-table [value]="selectedShoppingList.listItems" class="print-table">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 14%">MadCo Prod#</th>
      <th style="width: 14%">Mfgr Prod#</th>
      <th>Description</th>
      <th style="width: 12%">Qty to Order</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>{{item.productNumber}}</td>
      <td>{{item.manufacturerProdNum || 'UNKNOWN'}}</td>
      <td>{{item.description}}</td>
      <td>{{item.quantityToOrder}}</td>
    </tr>
  </ng-template>
</p-table>
<br>
<div>
  {{selectedShoppingList.message}}
</div>