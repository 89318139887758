import { keycloakDev } from './keycloak.env';

const BACKEND_BASE_URL = 'https://api.inventory-manager.dev.stmtires.com';
export const environment = {
  production: false,
  keycloak: keycloakDev,
  backendBaseURL: BACKEND_BASE_URL,
  apiURL: `${BACKEND_BASE_URL}/api/v1`,
  googleAnalyticsTag: '',
  companyNumberAccessList: ['213', '743'],
  thisCompanyNumber: ['213'],
  portalURL: 'https://portal.dev.stmtires.com',
  logo: 'assets/img/logo/STMLogo_HOR.svg'
};
