import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SlowMovingRequest, SlowMovingSocketMessage } from './slow-moving-request.interface';
import { environment } from '../../environments/environment';
import { SocketService } from '../shared/socket.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlowMovingRequestService {

  protected socketNamespaceName = 'slowmoving';
  private socketEvent = 'slowmoving';
  private socket: SocketService;

  private apiURL = `${environment.apiURL}/slow-moving-requests`;

  constructor(private http: HttpClient,) {
    this.socket = new SocketService(this.socketNamespaceName);
  }

  listen(): Observable<SlowMovingSocketMessage> {
    return this.socket.onEvent(this.socketEvent);
  }

  async triggerRequestCountSocket(queryJSON: {storeNumber: string | number, companyNumber: string | number}) {
    return this.http.post<any>(`${this.apiURL}/count`, queryJSON)
      .toPromise()
      .then((data) => { return data; });
  }

  async getRequestsForStore(queryJSON: {storeNumber: string | number, companyNumber: string | number}) {
    return this.http.post<any>(`${this.apiURL}/store`, queryJSON)
      .toPromise()
      .then(res => <SlowMovingRequest[]>res)
      .then((data) => { return data; });
  }

  async createSlowMovingRequest(requestJSON: any) {
    return this.http.post<any>(`${this.apiURL}`, requestJSON)
      .toPromise()
      // .then(res => <SlowMovingRequest>res)
      .then((data) => { return data; });
  }

  async getRequestById(requestId: string) {
    return this.http.get<any>(`${this.apiURL}/${requestId}`)
      .toPromise()
      .then(res => <SlowMovingRequest>res)
      .then((data) => { return data; });
  }

  async getRequestsByQuery(query: {}) {
    let params = new HttpParams();
    for(const key of Object.keys(query)){
      params = params.append(key, query[key]);
    }
    return this.http.get<any>(`${this.apiURL}/find`, {params})
      .toPromise()
      .then(res => <SlowMovingRequest[]>res)
      .then((data) => { return data; });
  }

  async updateRequest(requestId: string, requestJSON: any) {
    return this.http.put<any>(`${this.apiURL}/${requestId}`, requestJSON)
      .toPromise()
      // .then(res => <SlowMovingRequest>res)
      .then((data) => { return data; });
  }

  async deleteRequest(requestId: string) {
    return this.http.delete<any>(`${this.apiURL}/${requestId}`)
      .toPromise()
      .then((data) => { return data; });
  }

}
