import { Component } from '@angular/core';
import { LayoutService } from './layout/layout.service';
import { Layout } from './layout/layout.interface';
import { KeycloakService } from 'keycloak-angular';
import { UsersService } from './core/users.service';
import { Person } from './people/person.interface';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from './core/google-analytics.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'inventory-manager';
  user: Person;
  dialog: any = { visible: false };
  loadingApp = true;
  constructor(
    private keycloakService: KeycloakService,
    private layoutService: LayoutService,
    private userService: UsersService, // Don't remove this, it loads the token auto watcher;
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
  ) {
    this.dialogWatcher();
    if (environment.googleAnalyticsTag) {
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.googleAnalyticsTag, {
            page_path: event.urlAfterRedirects,
          });
        }
      });
    }
  }

  async ngOnInit() {
    const isLoggedIn = await this.keycloakService.isLoggedIn();
    if (isLoggedIn) {
      this.userService.loggedInUser$.subscribe((person: Person) => {
        if (person) {
          this.user = person;
          this.googleAnalyticsService.eventEmitter('App', 'Login', person.displayName);
        }
        this.initLayout();
      });
    }
    this.layoutService.loadingEvent.subscribe((e) => {
      this.loadingApp = e;
    })
  }

  initLayout(): void {
    const layout: Layout = {
      topbar: {
        menuItems: [{
          label: 'Buy',
          routerLink: '/purchasing',
          icon: 'far fa-shopping-cart fa-lg',
        // }, {
        //   label: 'Sell',
        //   routerLink: '/selling',
        //   icon: 'fad fa-truck-container fa-lg',
        }, {
          label: 'Shopping List',
          routerLink: '/shoppinglist',
          icon: 'far fa-clipboard-list-check fa-lg',
        }, {
          label: 'Slow Moving',
          routerLink: '/slow-moving',
          icon: 'far fa-turtle fa-lg',
        }],
        userMenu: {},
      },
    };
    if (this.user) {
      layout.topbar.userMenu = {
        displayName: this.user.displayName,
        menuItems: [
          {
            label: this.user.displayName,
            icon: 'fas fa-user',
            items: [
              {
                label: 'Logout',
                command: () => this.keycloakService.logout(),
                icon: 'fad fa-sign-out-alt',
              },
            ],
          },
        ],
      };
    }
    this.layoutService.init(layout).subscribe();
  }

  dialogWatcher() {
    this.layoutService.layout.subscribe((layout: Layout) => {
      this.dialog = layout.dialog || { visible: false };
    });
  }

  consoleLogFromPage(x: any) {
    console.log(x)
  }
}
