import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '../layout.service';
import { Layout, UserMenu } from '../layout.interface';
// import { NotificationsService, Notification } from '../notifications.service';
// import { Router } from '@angular/router';
// import { ToastService } from '../toast.service';
import { SlowMovingSocketMessage } from '../../slow-moving-request/slow-moving-request.interface';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, AfterViewInit {
  portalURL = environment.portalURL;
  logo = environment.logo;
  menuItems: MenuItem[] = [];
  userMenu: UserMenu = {};
  // notifications: Notification[] = [];
  // menuBadges: MenuBadge[] = [];
  // unreadNotificationCount: number = 0;
  // showNotificationsMenu: boolean = false;

  constructor(
    private layoutService: LayoutService,
    // private router: Router,
    // private notificationService: NotificationsService,
    // private toastService: ToastService,
  ) {
    layoutService.layout.subscribe((layout: Layout) => {
      this.menuItems = layout.topbar && layout.topbar.menuItems ? layout.topbar.menuItems : this.menuItems;
      this.userMenu = layout.topbar && layout.topbar.userMenu ? layout.topbar.userMenu : this.userMenu;
    });
  }

  ngOnInit(): void {
    // this.listenForNotifications();
  }

  ngAfterViewInit() {
    this.layoutService.loadingEvent.emit(false)
  }

  // listenForNotifications(): void {
  //   this.notificationService.listen().subscribe((notification: Notification) => {
  //     this.unreadNotificationCount++;
  //     this.notifications.unshift(notification);
  //     this.toastService.add({ severity: 'success', summary: notification.title, detail: notification.body });
  //     const audio = new Audio('../../../assets/sounds/notification.mp3');
  //     audio.play();
  //   });
  // }

  // toggleNotificationsMenu(): void {
  //   this.showNotificationsMenu = !this.showNotificationsMenu;
  //   this.unreadNotificationCount = 0;
  // }

  // navigate(notification: Notification): void {
  //   this.router.navigate([notification.path]);
  //   this.showNotificationsMenu = false;
  //   this.unreadNotificationCount = 0;
  //   notification.clicked = true;
  // }

  // onNotificationsHide(event: any): void {
  //   this.unreadNotificationCount = 0;
  // }

  // removeNotification(index: number): void {
  //   this.notifications.splice(index, 1);
  // }

  consoleLogFromPage(x: any) {
    console.log(x)
  }
}
