import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from './core/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
  },
  {
    path: 'purchasing',
    loadChildren: () => import('./inventory-purchasing/inventory-purchasing.module').then(m => m.InventoryPurchasingModule),
  },
  // {
  //   path: 'selling',
  //   loadChildren: () => import('./inventory-selling/inventory-selling.module').then(m => m.InventorySellingModule),
  // },
  {
    path: 'shoppinglist',
    loadChildren: () => import('./shopping-list/shopping-list.module').then(m => m.ShoppingListModule),
  },
  {
    path: 'slow-moving',
    loadChildren: () => import('./slow-moving-request/slow-moving-request.module').then(m => m.SlowMovingRequestModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
