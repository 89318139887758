import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AuthService } from './auth.service';
import { UsersService } from './users.service';
import { Person } from '../people/person.interface';

@Injectable({
  providedIn: 'root',
})
export class CanAuthenticationGuard extends KeycloakAuthGuard implements CanActivate {
  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    private authService: AuthService,
    private usersService: UsersService,
  ) {
    super(router, keycloakService);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      return new Promise(async (resolve, reject) => {
        if (!this.authenticated) {
          this.keycloakService.login().catch(e => console.error(e));
          return reject(false);
        }

        let loggedInUser: Person = this.usersService.loggedInUser;
        // const keycloakProfile = loggedInUser && loggedInUser.contexts && loggedInUser.contexts.keycloakProfile;
        // const attributeRoles = keycloakProfile && keycloakProfile.attributes && keycloakProfile.attributes.roles ?
        //   keycloakProfile.attributes.roles :
        //   [];
        // This is because we store roles in the attributes (going to change one day)
        // this.roles = this.roles.concat(attributeRoles || []);
        if (!loggedInUser) {
          loggedInUser = await this.authService.getUser();
        }

        if (!loggedInUser.stores) {
          this.authService.showMissingStoresMessage();
          resolve(false);
        }
        if (!loggedInUser.employeeId) {
          this.authService.showMissingEmployeeNumberMessage();
          resolve(false);
        }
        const requiredRoles: string[] = route.data.roles;
        if (!requiredRoles || requiredRoles.length === 0) {
          return resolve(true);
        }

        // if (!this.roles || this.roles.length === 0) {
        //   resolve(false);
        // }
        // resolve(requiredRoles.every(role => this.roles.indexOf(role) > -1));
      });
    } catch (err) {
      console.error(err)
    }
    
  }
}
