import { Component, OnInit } from '@angular/core';
import { ShoppingList } from '../shopping-list/shopping-list.interface';
import { ShoppingListService } from '../shopping-list/shopping-list.service';

@Component({
  selector: 'app-shopping-list-print-layout',
  templateUrl: './shopping-list-print-layout.component.html',
  styleUrls: ['./shopping-list-print-layout.component.scss']
})
export class ShoppingListPrintLayoutComponent implements OnInit {
  selectedShoppingList: ShoppingList;

  constructor(
    private shoppingListService: ShoppingListService,
  ) { }

  async ngOnInit() {
    this.shoppingListService.listToPrint$.subscribe((shoppingList: ShoppingList) => {
      if(shoppingList) {
        this.selectedShoppingList = shoppingList
      } else {
        this.selectedShoppingList = {
          listName: '',
          created: '',
          createdBy: '',
          storeNumber: null,
          companyNumber: null,
          message: '',
          listItems: [{
            productNumber: '',
            quantityToOrder: 0,
          }]
        }
      }
      
    }) 
  } 
}
