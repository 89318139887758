import { Component } from '@angular/core';
import { UsersService } from 'src/app/core/users.service';
import { Person } from 'src/app/people/person.interface';
import { StoreService } from 'src/app/stores/stores.service';
import { Store } from 'src/app/stores/stores.interface';
import { SlowMovingRequestService } from 'src/app/slow-moving-request/slow-moving-request.service';

@Component({
  selector: 'app-store-dropdown',
  templateUrl: './store-dropdown.component.html',
  styleUrls: ['./store-dropdown.component.scss'],
})
export class StoreDropdownComponent {
  loggedInUser: any;
  selectedStore: Store;
  constructor(
    private userService: UsersService,
    private storeService: StoreService,
    private slowMovingService: SlowMovingRequestService) {
    this.userService.loggedInUser$.subscribe((person: Person) => {
      if (person) {
        this.loggedInUser = person;
      }

      storeService.selectedStore.subscribe((store: Store) => {
        this.selectedStore = store;
      });

    });
  }

  async ngOnInit() {
    this.loggedInUser = await this.userService.loggedInUser$.toPromise();
    this.selectedStore = await this.storeService.selectedStore.toPromise();
    this.slowMovingService.triggerRequestCountSocket({storeNumber: this.selectedStore.storeNumber, companyNumber: this.selectedStore.companyNumber});
  }

  setSelectedStore(event: any): void {
    this.storeService.setSelectedStore(event.value);
    this.slowMovingService.triggerRequestCountSocket({storeNumber: this.selectedStore.storeNumber, companyNumber: this.selectedStore.companyNumber});
  }
}
