import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Person } from './person.interface';
import { Observable, of } from 'rxjs';
import { BaseHttpService } from '../shared/base-http.service';

@Injectable({ providedIn: 'root' })
export class PeopleService extends BaseHttpService {
  protected urlPath: string = 'people';
  constructor(protected http: HttpClient) {
    super();
  }


  getManagersByStoreNumber(storeNumber: number): Observable<any[]> {
    const params = { storeNumber };
    return this.http.get<any[]>(`${this.url}managers`, { params: this.getHttpParams(params) });
  }

  getManagers(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}managers`);
  }

}
