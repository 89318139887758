import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

export type QueryParams = { [key: string]: any };
export abstract class BaseHttpService {
  protected abstract urlPath: string;
  protected abstract http: HttpClient;

  constructor() {}

  protected get url(): string {
    return `${environment.apiURL}/${this.urlPath}/`;
  }

  /**
   * This is necessary because you can't directly inject an angular service (like HttpClient) into a base class
   * So it must be passed into derived constructor.
   * If it isn't, angular throws a really unhelpful error message.
   * This is here just to give a better error message that can be easily debugged.
   */
  public get _http(): HttpClient {
    if (!this.http) {
      throw new Error(
        `No HttpClient found in ${this.constructor.name}.
        You must inject 'http: HttpClient' in your service's constructor.
        Example:
          @Injectable({
            providedIn: 'root',
          })
          export class MySweetService extends CrudBaseService<SweetThang> {
            protected urlPath = '/sweet-thangs';

            constructor(protected http: HttpClient) {
              super();
            }
          }
        `,
      );
    }
    return this.http;
  }

  protected getHttpParams(params?: QueryParams): HttpParams {
    if (params) {
      let query: HttpParams = new HttpParams();
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          if (Array.isArray(params[key])) {
            // appends arrays with same key multiple times. Ex. ?id=1&id=2&id=3
            params[key].forEach((val: any) => {
              query = query.append(key, val.toString());
            });
          } else {
            // appends single key to query params
            query = query.append(key, params[key].toString());
          }
        }
      }
      return query;
    }
    return undefined;
  }
}
